var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"allcard",staticStyle:{"height":"calc(100vh - (151px))"}},[_c('simplebar',{staticClass:"simplebarcustom",attrs:{"data-simplebar-auto-hide":"false"}},[_c('div',{staticClass:"ContentHeader"},[_c('div',{staticClass:"HeaderAll"},[_vm._t("onlybreadcrumb"),_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")])],1),_vm._l((_vm.breadcrumb),function(item,i){return _c('a-breadcrumb-item',{key:i},[(i + 1 < _vm.breadcrumb.length)?_c('router-link',{attrs:{"to":{
                  name: item.nameroute,
                  params: item.param,
                  query: item.query,
                }}},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])],1)})],2)],2)]),_c('div',{staticClass:"formpeserta"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.Submit}},[_c('a-row',{attrs:{"gutter":[15, 15]}},[_c('a-col',{attrs:{"lg":8}},[_c('div',{ref:"heightCardForm",staticClass:"cardForm",style:(`min-height:${_vm.heightCardForm}px`)},[_c('h1',[_vm._v("Informasi Peserta")]),_c('a-form-item',{attrs:{"label":"Nama Peserta"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'nama_peserta',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'nama_peserta',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Field ini harus diisi',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-row',{attrs:{"gutter":[15, 15]}},[_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Jenis Kelamin"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'jenis_kelamin',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini harus diisi',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'jenis_kelamin',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Field ini harus diisi',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"Jenis Kelamin"},on:{"change":_vm.jenisKelamin}},[_c('a-select-option',{attrs:{"value":"M"}},[_c('a-icon',{attrs:{"type":"man"}}),_vm._v(" Pria")],1),_c('a-select-option',{attrs:{"value":"F"}},[_c('a-icon',{style:({ color: '#DF5684' }),attrs:{"type":"woman"}}),_vm._v(" Wanita")],1)],1)],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'status',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini harus diisi',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'status',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Field ini harus diisi',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"Status"},on:{"change":_vm.StatusKaryawan}},[_c('a-select-option',{attrs:{"value":"E"}},[_vm._v(" Employee")]),_c('a-select-option',{attrs:{"value":"S"}},[_vm._v(" Spouse")]),_c('a-select-option',{attrs:{"value":"C"}},[_vm._v(" Child")])],1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"Kategori Usia"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'kategori_usia',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'kategori_usia',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Field ini harus diisi',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"Kategori Usia"},on:{"change":_vm.kategoriUsia}},_vm._l((_vm.usia),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.title))])}),1)],1),_c('a-form-item',{attrs:{"label":"Plan"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'plan',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'plan',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Field ini harus diisi',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"Plan"}},_vm._l((_vm.plan),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.benefit.substring(0, item.benefit.length - 2)))])}),1)],1),_c('a-form-item',{attrs:{"label":"NIK"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'nik',
                      // {
                      //   rules: [
                      //     {
                      //       required: true,
                      //       message: 'Field ini harus diisi',
                      //     },
                      //   ],
                      // },
                    ]),expression:"[\n                      'nik',\n                      // {\n                      //   rules: [\n                      //     {\n                      //       required: true,\n                      //       message: 'Field ini harus diisi',\n                      //     },\n                      //   ],\n                      // },\n                    ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Tanggal Lahir"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'tgl_lahir',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'tgl_lahir',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Field ini harus diisi',\n                          },\n                        ],\n                      },\n                    ]"}],staticStyle:{"width":"100%"},attrs:{"autocomplete":"off","placeholder":"Input disini","format":"DD MMM YYYY"}})],1),(_vm.karyawanfield)?_c('a-form-item',{attrs:{"label":"Nama Karyawan"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'nama_karyawan',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Nama Karyawan harus diisi!',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'nama_karyawan',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Nama Karyawan harus diisi!',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"picsearch",attrs:{"show-search":"","label-in-value":"","placeholder":"Ketik untuk Mencari Nama Karyawan (min. 3 karakter)","not-found-content":_vm.isLoading ? undefined : 'Not Found',"filter-option":false},on:{"change":_vm.handleChangeKaryawan},nativeOn:{"keyup":function($event){return _vm.getKaryawan.apply(null, arguments)}}},[(_vm.isLoading)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l((_vm.dataKaryawan),function(data){return _c('a-select-option',{key:data.id_karyawan,attrs:{"value":data.id_karyawan}},[_vm._v(" "+_vm._s(`${data.nama_peserta} - ${data.no_peserta} - ${data.tgl_lahir_format}`)+" ")])})],2)],1):_vm._e()],1)]),_c('a-col',{attrs:{"lg":8}},[_c('div',{ref:"heightCardForm",staticClass:"cardForm",style:(`min-height:${_vm.heightCardForm}px`)},[_c('h1',[_vm._v("Bank Account")]),_c('a-form-item',{attrs:{"label":"Nama Bank"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(
                      _vm.rekening == 'rekening perusahaan'
                        ? ['nama_bank']
                        : [
                            'nama_bank',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Field ini harus diisi',
                                },
                              ],
                            },
                          ]
                    ),expression:"\n                      rekening == 'rekening perusahaan'\n                        ? ['nama_bank']\n                        : [\n                            'nama_bank',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'Field ini harus diisi',\n                                },\n                              ],\n                            },\n                          ]\n                    "}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"No.Rekening"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(
                      _vm.rekening == 'rekening perusahaan'
                        ? ['no_rekening']
                        : [
                            'no_rekening',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Field ini harus diisi',
                                },
                              ],
                            },
                          ]
                    ),expression:"\n                      rekening == 'rekening perusahaan'\n                        ? ['no_rekening']\n                        : [\n                            'no_rekening',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'Field ini harus diisi',\n                                },\n                              ],\n                            },\n                          ]\n                    "}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Nama Pemilik Rekening"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(
                      _vm.rekening == 'rekening perusahaan'
                        ? ['nama_rekening']
                        : [
                            'nama_rekening',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Field ini harus diisi',
                                },
                              ],
                            },
                          ]
                    ),expression:"\n                      rekening == 'rekening perusahaan'\n                        ? ['nama_rekening']\n                        : [\n                            'nama_rekening',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'Field ini harus diisi',\n                                },\n                              ],\n                            },\n                          ]\n                    "}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"No.Telp"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['no_telp']),expression:"['no_telp']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Alamat Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'email',
                      {
                        rules: [
                          {
                            type: 'email',
                            message: 'Format email masih salah',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'email',\n                      {\n                        rules: [\n                          {\n                            type: 'email',\n                            message: 'Format email masih salah',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),(_vm.isEmploye)?_c('div',{staticStyle:{"cursor":"pointer","margin-top":"25px"},on:{"click":_vm.TambahTanggungan}},[_c('a-icon',{attrs:{"type":"plus-circle"}}),_vm._v(" Tambah Tanggungan ")],1):_vm._e()],1)]),_c('a-col',{attrs:{"lg":8}},[_c('div',{ref:"heightCardForm",staticClass:"cardForm",style:(`min-height:${_vm.heightCardForm}px`)},[_c('h1',[_vm._v("Company Information")]),_c('a-form-item',{attrs:{"label":"Nama Group"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'group',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'group',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Field ini harus diisi',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"Nama Perusahaan"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'nama_perusahaan',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'nama_perusahaan',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Field ini harus diisi',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}},_vm._l((_vm.perusahaan),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.nama_perusahaan))])}),1)],1),_c('a-form-item',{attrs:{"label":"Cabang"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cabang']),expression:"['cabang']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Sub Cabang I"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sub_cabang_1']),expression:"['sub_cabang_1']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Sub Cabang II"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sub_cabang_2']),expression:"['sub_cabang_2']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Keterangan"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['keterangan']),expression:"['keterangan']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1)]),_c('a-col',{attrs:{"lg":24}},[_c('div',{staticClass:"submitcard",staticStyle:{"border":"1px solid #d8ddec"}},[_c('a-button',{attrs:{"type":"default"},on:{"click":_vm.batalkan}},[_vm._v("Batalkan")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Submit")])],1)])],1)],1)],1)])],1),_c('Confirm',{ref:"Confirm"}),_c('TambahPeserta',{ref:"tanggunan",on:{"done":_vm.tanggungan}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }