var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"footer":null,"width":"900px","centered":true},on:{"ok":_vm.handleOk},model:{value:(_vm.modals),callback:function ($$v) {_vm.modals=$$v},expression:"modals"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Tambah Tanggungan")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.Submit}},[_c('simplebar',{staticClass:"simplebarcustom",staticStyle:{"max-height":"510px"},attrs:{"data-simplebar-auto-hide":"false"}},_vm._l((_vm.valueadd),function(nourut,ih){return _c('div',{key:nourut,staticStyle:{"border":"1px solid #ccc","border-radius":"10px","padding":"20px","margin-bottom":"30px","position":"relative"}},[(ih >= 1)?_c('div',{staticStyle:{"position":"relative","background":"rgb(69, 114, 227)","top":"-38px","border-radius":"26px","padding":"5px 10px","color":"rgb(255, 255, 255)","margin":"0 auto","cursor":"pointer","margin-bottom":"-33px","display":"table"},on:{"click":function($event){return _vm.remove(nourut)}}},[_c('a-icon',{attrs:{"type":"minus-circle"}}),_vm._v(" Remove ")],1):_vm._e(),_c('a-row',{attrs:{"gutter":[15, 15]}},[_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Nama Peserta"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `nama_peserta-${nourut}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Field ini harus diisi',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `nama_peserta-${nourut}`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Field ini harus diisi',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `status-${nourut}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Field ini harus diisi',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `status-${nourut}`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Field ini harus diisi',\n                        },\n                      ],\n                    },\n                  ]"}],on:{"change":function($event){return _vm.StatusKaryawan($event, nourut)}}},[_c('a-select-option',{attrs:{"value":"S"}},[_vm._v(" Spouse")]),_c('a-select-option',{attrs:{"value":"C"}},[_vm._v(" Child")])],1)],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Jenis Kelamin"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `jenis_kelamin-${nourut}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Field ini harus diisi',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `jenis_kelamin-${nourut}`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Field ini harus diisi',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Jenis Kelamin"},on:{"change":function($event){return _vm.jenisKelamin($event, nourut)}}},[_c('a-select-option',{attrs:{"value":"M"}},[_c('a-icon',{attrs:{"type":"man"}}),_vm._v(" Pria")],1),_c('a-select-option',{attrs:{"value":"F"}},[_c('a-icon',{style:({ color: '#DF5684' }),attrs:{"type":"woman"}}),_vm._v(" Wanita")],1)],1)],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Tanggal Lahir"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `tgl_lahir-${nourut}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Field ini harus diisi',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `tgl_lahir-${nourut}`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Field ini harus diisi',\n                        },\n                      ],\n                    },\n                  ]"}],staticStyle:{"width":"100%"},attrs:{"autocomplete":"off","placeholder":"Input disini","format":"DD MMM YYYY"}})],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Kategori Usia"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `kategori_usia-${nourut}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Field ini harus diisi',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `kategori_usia-${nourut}`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Field ini harus diisi',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Kategori Usia"},on:{"change":function($event){return _vm.kategoriUsia($event, nourut)}}},_vm._l((_vm.usia[ih].usia),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.title))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Plan"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `plan-${nourut}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Field ini harus diisi',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `plan-${nourut}`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Field ini harus diisi',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"labelInValue":"","placeholder":"Plan"}},_vm._l((_vm.plan[ih].plan),function(item){return _c('a-select-option',{key:item.benefit.substring(0, item.benefit.length - 2),attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.benefit.substring(0, item.benefit.length - 2)))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Nama Bank"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(
                    _vm.rekening == 'rekening perusahaan'
                      ? [`nama_bank-${nourut}`]
                      : [
                          `nama_bank-${nourut}`,
                          {
                            initialValue: _vm.rekeningfrom.nama_bank,
                            rules: [
                              {
                                required: true,
                                message: 'Field ini harus diisi',
                              },
                            ],
                          },
                        ]
                  ),expression:"\n                    rekening == 'rekening perusahaan'\n                      ? [`nama_bank-${nourut}`]\n                      : [\n                          `nama_bank-${nourut}`,\n                          {\n                            initialValue: rekeningfrom.nama_bank,\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Field ini harus diisi',\n                              },\n                            ],\n                          },\n                        ]\n                  "}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"No.Rekening"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(
                    _vm.rekening == 'rekening perusahaan'
                      ? [`no_rekening-${nourut}`]
                      : [
                          `no_rekening-${nourut}`,
                          {
                            initialValue: _vm.rekeningfrom.no_rekening,
                            rules: [
                              {
                                required: true,
                                message: 'Field ini harus diisi',
                              },
                            ],
                          },
                        ]
                  ),expression:"\n                    rekening == 'rekening perusahaan'\n                      ? [`no_rekening-${nourut}`]\n                      : [\n                          `no_rekening-${nourut}`,\n                          {\n                            initialValue: rekeningfrom.no_rekening,\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Field ini harus diisi',\n                              },\n                            ],\n                          },\n                        ]\n                  "}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1),_c('a-col',{attrs:{"lg":24}},[_c('a-form-item',{attrs:{"label":"Nama Pemilik Rekening"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(
                    _vm.rekening == 'rekening perusahaan'
                      ? [`nama_rekening-${nourut}`]
                      : [
                          `nama_rekening-${nourut}`,
                          {
                            initialValue: _vm.rekeningfrom.nama_rekening,
                            rules: [
                              {
                                required: true,
                                message: 'Field ini harus diisi',
                              },
                            ],
                          },
                        ]
                  ),expression:"\n                    rekening == 'rekening perusahaan'\n                      ? [`nama_rekening-${nourut}`]\n                      : [\n                          `nama_rekening-${nourut}`,\n                          {\n                            initialValue: rekeningfrom.nama_rekening,\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Field ini harus diisi',\n                              },\n                            ],\n                          },\n                        ]\n                  "}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1)],1)],1)}),0),_c('div',{staticStyle:{"cursor":"pointer","margin-top":"25px"},on:{"click":_vm.tambah}},[_c('a-icon',{attrs:{"type":"plus-circle"}}),_vm._v(" Tambah Lagi ")],1),_c('a-form-item',{attrs:{"wrapper-col":{ lg: 17, offset: 20 }}},[_c('a-button',{staticStyle:{"margin-right":"8px"},attrs:{"type":"batal"},on:{"click":_vm.Batal}},[_vm._v(" Batal ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }