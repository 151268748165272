<template>
  <div>
    <a-modal
      v-model="modals"
      @ok="handleOk"
      :footer="null"
      width="900px"
      :centered="true"
    >
      <div slot="title">Tambah Tanggungan</div>
      <a-form :form="form" @submit="Submit">
        <simplebar
          class="simplebarcustom"
          data-simplebar-auto-hide="false"
          style="max-height: 510px"
        >
          <div
            style="
              border: 1px solid #ccc;
              border-radius: 10px;
              padding: 20px;
              margin-bottom: 30px;
              position: relative;
            "
            v-for="(nourut, ih) in valueadd"
            :key="nourut"
          >
            <div
              style="
                position: relative;
                background: rgb(69, 114, 227);
                top: -38px;
                border-radius: 26px;
                padding: 5px 10px;
                color: rgb(255, 255, 255);
                margin: 0 auto;
                cursor: pointer;
                margin-bottom: -33px;
                display: table;
              "
              v-if="ih >= 1"
              @click="remove(nourut)"
            >
              <a-icon type="minus-circle" /> Remove
            </div>
            <a-row :gutter="[15, 15]">
              <a-col :lg="12">
                <a-form-item label="Nama Peserta">
                  <a-input
                    autocomplete="off"
                    v-decorator="[
                      `nama_peserta-${nourut}`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]"
                    placeholder="Input disini"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="12">
                <a-form-item label="Status">
                  <a-select
                    v-decorator="[
                      `status-${nourut}`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]"
                    @change="StatusKaryawan($event, nourut)"
                  >
                    <a-select-option value="S"> Spouse</a-select-option>

                    <a-select-option value="C"> Child</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :lg="12">
                <a-form-item label="Jenis Kelamin">
                  <a-select
                    v-decorator="[
                      `jenis_kelamin-${nourut}`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]"
                    placeholder="Jenis Kelamin"
                    @change="jenisKelamin($event, nourut)"
                  >
                    <a-select-option value="M"
                      ><a-icon type="man" /> Pria</a-select-option
                    >
                    <a-select-option value="F"
                      ><a-icon type="woman" :style="{ color: '#DF5684' }" />
                      Wanita</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :lg="12">
                <a-form-item label="Tanggal Lahir">
                  <a-date-picker
                    style="width: 100%"
                    autocomplete="off"
                    v-decorator="[
                      `tgl_lahir-${nourut}`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]"
                    placeholder="Input disini"
                    format="DD MMM YYYY"
                  />
                </a-form-item>
              </a-col>

              <a-col :lg="12">
                <a-form-item label="Kategori Usia">
                  <a-select
                    v-decorator="[
                      `kategori_usia-${nourut}`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]"
                    placeholder="Kategori Usia"
                    @change="kategoriUsia($event, nourut)"
                  >
                    <a-select-option
                      v-for="item in usia[ih].usia"
                      :value="item.id"
                      :key="item.id"
                    >
                      {{ item.title }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :lg="12">
                <a-form-item label="Plan">
                  <a-select
                    labelInValue
                    v-decorator="[
                      `plan-${nourut}`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Field ini harus diisi',
                          },
                        ],
                      },
                    ]"
                    placeholder="Plan"
                  >
                    <a-select-option
                      v-for="item in plan[ih].plan"
                      :key="item.benefit.substring(0, item.benefit.length - 2)"
                      :value="item.id"
                    >
                      {{
                        item.benefit.substring(0, item.benefit.length - 2)
                      }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :lg="12">
                <a-form-item label="Nama Bank">
                  <a-input
                    autocomplete="off"
                    v-decorator="
                      rekening == 'rekening perusahaan'
                        ? [`nama_bank-${nourut}`]
                        : [
                            `nama_bank-${nourut}`,
                            {
                              initialValue: rekeningfrom.nama_bank,
                              rules: [
                                {
                                  required: true,
                                  message: 'Field ini harus diisi',
                                },
                              ],
                            },
                          ]
                    "
                    placeholder="Input disini"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="12">
                <a-form-item label="No.Rekening">
                  <a-input
                    autocomplete="off"
                    v-decorator="
                      rekening == 'rekening perusahaan'
                        ? [`no_rekening-${nourut}`]
                        : [
                            `no_rekening-${nourut}`,
                            {
                              initialValue: rekeningfrom.no_rekening,
                              rules: [
                                {
                                  required: true,
                                  message: 'Field ini harus diisi',
                                },
                              ],
                            },
                          ]
                    "
                    placeholder="Input disini"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="24">
                <a-form-item label="Nama Pemilik Rekening">
                  <a-input
                    autocomplete="off"
                    v-decorator="
                      rekening == 'rekening perusahaan'
                        ? [`nama_rekening-${nourut}`]
                        : [
                            `nama_rekening-${nourut}`,
                            {
                              initialValue: rekeningfrom.nama_rekening,
                              rules: [
                                {
                                  required: true,
                                  message: 'Field ini harus diisi',
                                },
                              ],
                            },
                          ]
                    "
                    placeholder="Input disini"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </simplebar>

        <div style="cursor: pointer; margin-top: 25px" @click="tambah">
          <a-icon type="plus-circle" /> Tambah Lagi
        </div>

        <a-form-item :wrapper-col="{ lg: 17, offset: 20 }">
          <a-button type="batal" @click="Batal" style="margin-right: 8px">
            Batal
          </a-button>
          <a-button type="primary" html-type="submit"> Submit </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormAction" });
  },
  components: {
    simplebar,
  },
  data() {
    return {
      modals: false,
      fileList: [],
      iconStatus: null,
      id: [],
      plan: [],
      peserta: [],
      isLoading: false,
      perusahaan: [],
      idperusahaan: "",
      datedisable: 0,
      add: 1,
      valueadd: [],
      usia: [],
      rekening: "",
      rekeningfrom: {
        nama_bank: "",
        no_rekening: "",
        nama_rekening: "",
      },
    };
  },
  mounted() {},
  destroyed() {},
  methods: {
    moment,
    remove(e) {
      const index = this.valueadd.indexOf(e);
      if (index > -1) {
        this.valueadd.splice(index, 1);
        this.usia.splice(index, 1);
        this.plan.splice(index, 1);
      }
    },
    tambah() {
      //console.log(this.form);
      this.add += 1;
      this.valueadd.push(this.add);

      this.usia.push({
        nourut: this.add,
        usia: [],
      });

      this.plan.push({
        nourut: this.add,
        plan: [],
      });
    },

    StatusKaryawan(e, nourut) {
      this.form.resetFields("kategori_usia-" + nourut);
      this.form.resetFields("plan-" + nourut);
      if (this.form.getFieldValue("jenis_kelamin-" + nourut) != null) {
        let gender = this.form.getFieldValue("jenis_kelamin-" + nourut);
        this.getTitleBenefit(gender, e, nourut);
      }
    },
    kategoriUsia(e, nourut) {
      this.form.resetFields("plan-" + nourut);
      this.$store
        .dispatch("getKategoriUsia", {
          id: e,
        })
        .then((response) => {
          const index = this.plan.map((key) => key.nourut).indexOf(nourut);
          //this.plan[index] = { nourut: nourut, usia: response.data.data };

          this.plan.map((key, i) => {
            if (i == index) {
              key.plan = response.data.data;
            }
          });
        });
    },
    jenisKelamin(e, nourut) {
      //alert(nourut);
      this.form.resetFields("kategori_usia-" + nourut);
      this.form.resetFields("plan-" + nourut);
      if (this.form.getFieldValue("status-" + nourut) != null) {
        let status = this.form.getFieldValue("status-" + nourut);
        this.getTitleBenefit(e, status, nourut);

        //console.log(this.usia.map((key) => key.nourut).indexOf(nourut));
        //console.log(this.form.getFieldValue("jenis_kelamin"));
      }
      // else {
      //   this.$message.error("Status karyawan belum dipilih");
      // }
    },
    show(rekening, rekeningfrom) {
      //console.log(rekeningfrom);
      this.rekeningfrom = rekeningfrom;
      this.modals = true;
      this.rekening = rekening;
      if (this.valueadd.length == 0) {
        this.valueadd.push(1);

        this.usia.push({
          nourut: 1,
          usia: [],
        });

        this.plan.push({
          nourut: 1,
          plan: [],
        });
      }
    },

    Submit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          //console.log("tes", values["plan-1"].key);
          //console.log(values);
          let tanggungan = [];
          tanggungan = this.valueadd.map((key) => ({
            nama_peserta_tanggungan: values["nama_peserta-" + key],
            tgl_lahir_tanggungan: moment(values["tgl_lahir-" + key]).format(
              "YYYY-MM-DD"
            ),
            jenis_kelamin_tanggungan: values["jenis_kelamin-" + key],
            status_tanggungan: values["status-" + key],
            kategori_usia_tanggungan: values["kategori_usia-" + key],
            other_plan_tanggungan: values["plan-" + key],

            nama_bank_tanggungan: values["nama_bank-" + key],
            no_rekening_tanggungan: values["no_rekening-" + key],
            rekening_atas_nama_tanggungan: values["nama_rekening-" + key],
          }));

          //console.log(tanggungan);

          this.modals = false;

          this.$emit("done", tanggungan);
        }
      });
    },
    handleOk() {},
    Batal() {
      let tanggungan = [];
      this.form.resetFields();
      this.modals = false;
      this.$emit("done", tanggungan);
    },
    getTitleBenefit(gender, status, nourut) {
      this.isLoading = true;
      this.$store
        .dispatch("getTitleBenefit", {
          jenis_kelamin: gender,
          status: status,
          id: this.$route.params.id,
        })
        .then((response) => {
          this.isLoading = false;

          const index = this.usia.map((key) => key.nourut).indexOf(nourut);
          //this.usia[index] = { nourut: nourut, usia: response.data.data };
          //this.usia = [{ nourut: nourut, usia: response.data.data }];
          this.usia.map((key, i) => {
            if (i == index) {
              key.usia = response.data.data;
            }
          });
        });
    },
  },
};
</script>

<style>
.ModalCustom .ant-modal-content {
  padding: 2px 5px !important;
}

.ModalCustom .ant-modal-header {
  border-radius: 24px !important;
}

.ModalCustom .ant-modal-close-x {
  margin-right: 20px;
}
</style>
