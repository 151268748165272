<template>
  <div>
    <div class="allcard" style="height: calc(100vh - (151px))">
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <!-- Start Header -->
        <div class="ContentHeader">
          <div class="HeaderAll">
            <!-- Slot Breadcrumb -->
            <slot name="onlybreadcrumb"></slot>
            <a-breadcrumb>
              <a-breadcrumb-item
                ><router-link :to="{ name: 'Home' }">Home</router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
                <router-link
                  :to="{
                    name: item.nameroute,
                    params: item.param,
                    query: item.query,
                  }"
                  v-if="i + 1 < breadcrumb.length"
                  >{{ item.name }}</router-link
                >
                <span v-else>{{ item.name }}</span>
              </a-breadcrumb-item>
            </a-breadcrumb>
          </div>
          <!-- Slot Benefit Header-->
        </div>
        <!-- End Header -->
        <div class="formpeserta">
          <!-- Start Content Here -->
          <a-form :form="form" @submit="Submit">
            <a-row :gutter="[15, 15]">
              <a-col :lg="8">
                <div
                  class="cardForm"
                  ref="heightCardForm"
                  :style="`min-height:${heightCardForm}px`"
                >
                  <h1>Informasi Peserta</h1>

                  <a-form-item label="Nama Peserta">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'nama_peserta',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>

                  <a-row :gutter="[15, 15]">
                    <a-col :lg="12">
                      <a-form-item label="Jenis Kelamin">
                        <a-select
                          v-decorator="[
                            'jenis_kelamin',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Field ini harus diisi',
                                },
                              ],
                            },
                          ]"
                          placeholder="Jenis Kelamin"
                          @change="jenisKelamin"
                        >
                          <a-select-option value="M"
                            ><a-icon type="man" /> Pria</a-select-option
                          >
                          <a-select-option value="F"
                            ><a-icon
                              type="woman"
                              :style="{ color: '#DF5684' }"
                            />
                            Wanita</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col :lg="12">
                      <a-form-item label="Status">
                        <a-select
                          v-decorator="[
                            'status',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Field ini harus diisi',
                                },
                              ],
                            },
                          ]"
                          placeholder="Status"
                          @change="StatusKaryawan"
                        >
                          <a-select-option value="E"> Employee</a-select-option>
                          <a-select-option value="S"> Spouse</a-select-option>

                          <a-select-option value="C"> Child</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>

                  <a-form-item label="Kategori Usia">
                    <a-select
                      v-decorator="[
                        'kategori_usia',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Kategori Usia"
                      @change="kategoriUsia"
                    >
                      <a-select-option
                        v-for="(item, i) in usia"
                        :value="item.id"
                        :key="i"
                      >
                        {{ item.title }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>

                  <a-form-item label="Plan">
                    <a-select
                      v-decorator="[
                        'plan',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Plan"
                    >
                      <a-select-option
                        v-for="(item, i) in plan"
                        :value="item.id"
                        :key="i"
                      >
                        {{
                          item.benefit.substring(0, item.benefit.length - 2)
                        }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>

                  <a-form-item label="NIK">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'nik',
                        // {
                        //   rules: [
                        //     {
                        //       required: true,
                        //       message: 'Field ini harus diisi',
                        //     },
                        //   ],
                        // },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>

                  <a-form-item label="Tanggal Lahir">
                    <a-date-picker
                      style="width: 100%"
                      autocomplete="off"
                      v-decorator="[
                        'tgl_lahir',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                      format="DD MMM YYYY"
                    />
                  </a-form-item>

                  <a-form-item label="Nama Karyawan" v-if="karyawanfield">
                    <a-select
                      show-search
                      label-in-value
                      placeholder="Ketik untuk Mencari Nama Karyawan (min. 3 karakter)"
                      :not-found-content="isLoading ? undefined : 'Not Found'"
                      :filter-option="false"
                      @change="handleChangeKaryawan"
                      @keyup.native="getKaryawan"
                      class="picsearch"
                      v-decorator="[
                        'nama_karyawan',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Nama Karyawan harus diisi!',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-spin
                        v-if="isLoading"
                        slot="notFoundContent"
                        size="small"
                      />
                      <a-select-option
                        v-for="data in dataKaryawan"
                        :key="data.id_karyawan"
                        :value="data.id_karyawan"
                      >
                        {{
                          `${data.nama_peserta} - ${data.no_peserta} - ${data.tgl_lahir_format}`
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </a-col>

              <a-col :lg="8">
                <div
                  class="cardForm"
                  ref="heightCardForm"
                  :style="`min-height:${heightCardForm}px`"
                >
                  <h1>Bank Account</h1>

                  <a-form-item label="Nama Bank">
                    <a-input
                      autocomplete="off"
                      v-decorator="
                        rekening == 'rekening perusahaan'
                          ? ['nama_bank']
                          : [
                              'nama_bank',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'Field ini harus diisi',
                                  },
                                ],
                              },
                            ]
                      "
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="No.Rekening">
                    <a-input
                      autocomplete="off"
                      v-decorator="
                        rekening == 'rekening perusahaan'
                          ? ['no_rekening']
                          : [
                              'no_rekening',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'Field ini harus diisi',
                                  },
                                ],
                              },
                            ]
                      "
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="Nama Pemilik Rekening">
                    <a-input
                      autocomplete="off"
                      v-decorator="
                        rekening == 'rekening perusahaan'
                          ? ['nama_rekening']
                          : [
                              'nama_rekening',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'Field ini harus diisi',
                                  },
                                ],
                              },
                            ]
                      "
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="No.Telp">
                    <a-input
                      autocomplete="off"
                      v-decorator="['no_telp']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="Alamat Email">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'email',
                        {
                          rules: [
                            {
                              type: 'email',
                              message: 'Format email masih salah',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <div
                    style="cursor: pointer; margin-top: 25px"
                    @click="TambahTanggungan"
                    v-if="isEmploye"
                  >
                    <a-icon type="plus-circle" /> Tambah Tanggungan
                  </div>
                </div>
              </a-col>
              <a-col :lg="8">
                <div
                  class="cardForm"
                  ref="heightCardForm"
                  :style="`min-height:${heightCardForm}px`"
                >
                  <h1>Company Information</h1>
                  <a-form-item label="Nama Group">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'group',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                      :disabled="true"
                    />
                  </a-form-item>
                  <a-form-item label="Nama Perusahaan">
                    <a-select
                      autocomplete="off"
                      v-decorator="[
                        'nama_perusahaan',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                    >
                      <a-select-option
                        v-for="(item, i) in perusahaan"
                        :key="i"
                        :value="item.id"
                        >{{ item.nama_perusahaan }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Cabang">
                    <a-input
                      autocomplete="off"
                      v-decorator="['cabang']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="Sub Cabang I">
                    <a-input
                      autocomplete="off"
                      v-decorator="['sub_cabang_1']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="Sub Cabang II">
                    <a-input
                      autocomplete="off"
                      v-decorator="['sub_cabang_2']"
                      placeholder="Input disini"
                    />
                  </a-form-item>

                  <a-form-item label="Keterangan">
                    <a-textarea
                      autocomplete="off"
                      v-decorator="['keterangan']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </div>
              </a-col>
              <a-col :lg="24">
                <div class="submitcard" style="border: 1px solid #d8ddec">
                  <a-button type="default" @click="batalkan">Batalkan</a-button>
                  <a-button type="primary" html-type="submit">Submit</a-button>
                </div>
              </a-col>
            </a-row>
          </a-form>
          <!-- End Content Here -->
        </div>
      </simplebar>
    </div>
    <Confirm ref="Confirm" />
    <TambahPeserta ref="tanggunan" @done="tanggungan" />
  </div>
</template>
<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import Confirm from "@/components/Modal/confirmDataPeserta.vue";
import TambahPeserta from "@/components/Modal/TambahTanggungan.vue";
import moment from "moment";

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "Form Data Peserta" });
  },

  components: {
    Confirm,
    simplebar,
    TambahPeserta,
  },
  data() {
    return {
      heightCardForm: 0,
      perusahaan: [],
      plan: [],
      usia: [],
      dataKaryawan: [],
      karyawanfield: false,
      isEmploye: false,
      timeout: null,
      timeoutValue: 500,
      minChar: 2,
      isLoading: false,
      breadcrumb: [
        {
          name: "Daftar Group",
          nameroute: "ListDaftarGroup",
          param: {},
        },
        {
          name: "Data Peserta",
          nameroute: "ListDataPeserta",
          param: { id: this.$route.params.id },
        },
        {
          name: "Tambah Peserta",
          nameroute: "",
          param: {},
        },
      ],
      rekening: "",
      tanggungans: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from);

      if (from.name != null) {
        vm.breadcrumb = [
          {
            name: "Daftar Group",
            nameroute: "ListDaftarGroup",
            param: {},
          },
          {
            name: from.meta.title,
            nameroute: from.name,
            param: { id: from.params.id },
            query: from.query,
          },
          {
            name: "Tambah Peserta",
            nameroute: "",
            param: {},
          },
        ];
      } else {
        vm.breadcrumb = [
          {
            name: "Daftar Group",
            nameroute: "ListDaftarGroup",
            param: {},
          },
          {
            name: "Data Peserta",
            nameroute: "ListDataPeserta",
            param: { id: vm.$route.params.id },
          },
          {
            name: "Tambah Peserta",
            nameroute: "",
            param: {},
          },
        ];
      }
    });
  },
  mounted() {
    this.matcheight();
    this.getPerusahaan();
    this.getRekeningTc();
  },
  methods: {
    moment,
    tanggungan(n) {
      //console.log(n);
      this.tanggungans = n;
    },
    TambahTanggungan() {
      let rekeningfrom = {
        nama_bank: this.form.getFieldValue("nama_bank"),
        no_rekening: this.form.getFieldValue("no_rekening"),
        nama_rekening: this.form.getFieldValue("nama_rekening"),
      };

      this.$refs.tanggunan.show(this.rekening, rekeningfrom);
    },
    KaryawanGetField(e) {
      this.$store
        .dispatch("getKaryawanfield", {
          id_job_order: this.$route.params.id,
          id_peserta: e,
        })
        .then((response) => {
          //console.log(response.data.data);
          this.form.setFieldsValue({
            nama_bank: response.data.data.nama_bank,
            no_rekening: response.data.data.no_rekening,
            nama_rekening: response.data.data.rekening_atas_nama,
            no_telp: response.data.data.no_telp,
            email: response.data.data.alamat_email,
            nama_perusahaan: response.data.data.id_job_order_perusahaan,
            cabang: response.data.data.cabang,
            sub_cabang_1: response.data.data.sub_cabang_satu,
          });

          // alert(response.data.data.id_job_order_perusahaan);

          // this.form.setFieldsValue({
          //   nama_bank: response.data.data.id_job_order_perusahaan,
          // });
        });
    },
    getKaryawan(e) {
      if (e.target.value == "") {
        this.dataKaryawan = [];
      } else {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (e.target.value.length > this.minChar) {
            this.isLoading = true;
            this.$store
              .dispatch("GetKaryawan", {
                id: this.$route.params.id,
                // id_job_order_perusahaan: this.perusahaan,
                search: e.target.value,
              })
              .then((response) => {
                this.isLoading = false;
                this.dataKaryawan = response.data.data;
              })
              .catch((error) => {
                this.isLoading = false;
              });
          }
        }, this.timeoutValue);
        //this.$store.
      }
    },
    handleChangeKaryawan(value) {
      if (typeof value !== "undefined") {
        // this.idPasien = value.key;

        this.KaryawanGetField(value.key);
        // this.getPasienDetail(this.idPasien);

        Object.assign(this, {
          value,
          dataKaryawan: [],
        });
      }
    },
    kategoriUsia(e) {
      //alert(e);
      this.form.resetFields("plan");
      this.$store
        .dispatch("getKategoriUsia", {
          id: e,
        })
        .then((response) => {
          this.plan = response.data.data;
        });
    },
    jenisKelamin(e) {
      this.form.resetFields("kategori_usia");
      this.form.resetFields("plan");
      if (this.form.getFieldValue("status") != null) {
        let status = this.form.getFieldValue("status");
        this.getTitleBenefit(e, status);
        //console.log(this.form.getFieldValue("jenis_kelamin"));
      }
      // else {
      //   this.$message.error("Status karyawan belum dipilih");
      // }
    },

    StatusKaryawan(e) {
      //    this.form.setFieldsValue({
      //         nama_bank: response.data.data.nama_bank,
      //         no_rekening: response.data.data.no_rekening,
      //         nama_rekening: response.data.data.rekening_atas_nama,
      //         no_telp: response.data.data.no_telp,
      //         email: response.data.data.alamat_email,
      //         id_job_order_perusahaan: response.data.data.id_job_order_perusahaan,
      //         cabang: response.data.data.cabang,
      //         sub_cabang_1: response.data.data.sub_cabang_satu,
      //       });
      // });

      this.form.resetFields("kategori_usia");
      this.form.resetFields("plan");
      if (this.form.getFieldValue("jenis_kelamin") != null) {
        let gender = this.form.getFieldValue("jenis_kelamin");
        this.getTitleBenefit(gender, e);
        // console.log(this.form.getFieldValue("jenis_kelamin"));
      }
      // else {
      //   this.$message.error("Jenis kelamin belum dipilih");
      // }

      this.karyawanfield = e == "E" ? false : true;

      this.isEmploye = e == "E" ? true : false;
    },
    getPerusahaan() {
      this.$store
        .dispatch("GetDaftarPerusahaan", {
          page: 1,
          pageSizes: 15,
          id: this.$route.params.id,
        })
        .then((response) => {
          this.perusahaan = response.data.data;
          this.form.setFieldsValue({ group: this.perusahaan[0].nama_group });
        });
    },
    matcheight() {
      this.heightCardForm = this.$refs.heightCardForm.clientHeight;
    },
    batalkan() {
      this.$router.push({ name: "ListDataPeserta" });
    },
    Submit(e) {
      e.preventDefault();

      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          // console.log("tes", this.plan);
          //this.$refs.Confirm.Show();
          let b = {
            ...values,
            tanggungan: this.tanggungans,
          };
          let a = {
            name_perusahaan_real: this.perusahaan.filter(
              (key) => key.id == values.nama_perusahaan
            )[0].nama_perusahaan,
            othersplan: this.plan.filter((key) => key.id == values.plan),
          };
          this.$refs.Confirm.Show({ ...a, ...b }, "Create");
        }
      });
    },

    getTitleBenefit(gender, status) {
      this.$store
        .dispatch("getTitleBenefit", {
          jenis_kelamin: gender,
          status: status,
          id: this.$route.params.id,
        })
        .then((response) => {
          this.usia = response.data.data;

          // console.log(this.usia);
        });
    },

    getRekeningTc() {
      this.$store
        .dispatch("getRekening", {
          job_order: this.$route.params.id,
        })
        .then((response) => {
          //console.log(response.data.data);
          this.rekening = response.data.data.ketentuan;
        });
    },
  },
};
</script>
